import React from "react"
import { styles } from "../../utils/css"
import Logo from "./Logo"
import { Paragraph } from "./Text"
import { Heading } from "./Text"
import ProjectImageResponsiveNonRetina from "./ProjectImageResponsiveNonRetina"
import ProjectImageResponsive from "./ProjectImageResponsive"

import logoBarkBox from "../../images/logo-bb@2x.png"
import ImgBarkBoxWeb from "./ImgBarkBoxWeb"
import ImgBarkBoxWebSettings from "./ImgBarkBoxWebSettings"
import ImgBarkBoxOverview from "./ImgBarkBoxOverview"

const ProjectBarkBox = () => {
  return (
    <div>
      <div className="m-auto max-w1080px px4 px10-md">
        <div className="border-top border-grey-900 mt6" id="barkbox">
          <Logo src={logoBarkBox} alt="BarkBox logo" />
          <div className="flex flex-wrap flex-nowrap-sm pb10 pb20-md">
            <div className="basis100p basis40p-sm basis50p-md text-center text-left-sm mb10 mb0-sm pr0 pr4-sm">
              <Heading kind="pHeading1">BarkBox</Heading>
              <p className="maison-book grey-900 fz16px inline-block mb5">
                2012
              </p>
              {/* <h6 className="fz16px maison-book block mb3 mb4-sm grey-900 mt2">
                Role
              </h6> */}
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb2-sm">
                Lead Product Designer
              </h3>
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb2-sm">
                Frontend Designer
              </h3>
            </div>
            <div className="basis100p basis60p-sm basis50p-md">
              <Heading kind="pHeading3">Redesign</Heading>
              <Paragraph>
                I was responsible for a full{" "}
                <span className={styles("maison-demi")}>
                  responsive redesign
                </span>
                .
              </Paragraph>
              <Paragraph>
                This includes everything from subscribing to account settings,
                and marketing pages to ads. It was all tied together by creating
                the{" "}
                <span className={styles("maison-demi")}>
                  initial Design System
                </span>{" "}
                later expanded on by the growing team.
              </Paragraph>
              <Heading kind="pHeading3" className="mt8 mt10-md">
                New Branding
              </Heading>
              <Paragraph>
                BarkBox wanted to do good in the world, and have fun at the same
                time. And the brand reflects that with{" "}
                <span className={styles("maison-demi")}>
                  charming hand-drawn illustrations
                </span>{" "}
                and a{" "}
                <span className={styles("maison-demi")}>sprinkle of humor</span>{" "}
                in their copy.
              </Paragraph>
              <Paragraph>
                This was loads of fun right down to pitching in on hand-stamping
                and filling each BarkBox together. We even got the tissue paper
                inside to match the BarkBox blue.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <ProjectImageResponsive src={<ImgBarkBoxOverview />} />
      <ProjectImageResponsiveNonRetina src={<ImgBarkBoxWeb />} />
      <ProjectImageResponsiveNonRetina
        src={<ImgBarkBoxWebSettings />}
        captionTitle="Redesign and New Brand Launch"
        caption="We better explained how the service worked through videos, and showed what to expect inside a typical BarkBox."
        caption2="The added clarity sped up folks who chose to subscribe because they knew what to expect."
      />
    </div>
  )
}

export default ProjectBarkBox
