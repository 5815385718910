import React from "react"
import { Paragraph } from "./Text"

const Project3UpResponsive = ({
  src1,
  src2,
  src3,
  src4,
  captionTitle,
  caption,
  caption2,
}) => {
  return (
    <div className="px0 px10-md pb5 pb10-md">
      <div className="m-auto max-w1300px">
        <div className="flex flex-wrap flex-nowrap-sm justify-between">
          <div className="basis50p p1 p0-sm basis30p-sm">{src1}</div>
          <div className="basis50p p1 p0-sm basis30p-sm">{src2}</div>
          <div className="basis50p p1 p0-sm basis30p-sm">{src3}</div>
          <div className="basis50p p1 p0-sm block hide-sm">{src4}</div>
        </div>
      </div>
      {captionTitle && caption && (
        <div className="m-auto max-w1080px flex flex-wrap flex-nowrap-sm justify-end px4 px10-md">
          <div className="basis100p basis40p-sm basis50p-md" />
          <div className="basis100p basis60p-sm basis50p-md">
            <div className="fz28px ls36px fz34px-sm ls42px-sm fz38px-lg ls48px-lg maison-demi grey-900 mb5 mb5-md mt5 mt6-md">
              {captionTitle}
            </div>
            <Paragraph>{caption}</Paragraph>
            {caption2 && <Paragraph>{caption2}</Paragraph>}
          </div>
        </div>
      )}
    </div>
  )
}

export default Project3UpResponsive
