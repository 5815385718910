import React from "react"
import { styles } from "../../utils/css"
import Logo from "./Logo"
import { Paragraph } from "./Text"
import { Heading } from "./Text"
import Project3UpResponsive from "./Project3UpResponsive"
import ProjectImageResponsive from "./ProjectImageResponsive"

import logoTimeInc from "../../images/logo-timeinc@2x.png"
import ImgTimeIncBrand from "./ImgTimeIncBrand"
import ImgTimeIncPatternLabColors from "./ImgTimeIncPatternLabColors"
import ImgTimeIncPatternLabComponents from "./ImgTimeIncPatternLabComponents"
import ImgTimeIncPatternLabFactoids from "./ImgTimeIncPatternLabFactoids"
import ImgTimeIncPatternLabBlocks from "./ImgTimeIncPatternLabBlocks"

const ProjectTimeInc = () => {
  return (
    <div>
      <div className="m-auto max-w1080px px4 px10-md">
        <div className="border-top border-grey-900 mt6" id="timeinc">
          <Logo src={logoTimeInc} alt="Time Inc. logo" />
          <div className="flex flex-wrap flex-nowrap-sm pb10 pb20-md">
            <div className="basis100p basis40p-sm basis50p-md text-center text-left-sm mb10 mb0-sm pr0 pr4-sm">
              <Heading kind="pHeading1">Time Inc.</Heading>
              <p className="maison-book grey-900 fz16px inline-block mb5">
                2013
              </p>
              {/* <h6 className="fz16px maison-book block mb3 mb4-sm grey-900 mt2">
                Role
              </h6> */}
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb7-sm">
                Frontend Designer
              </h3>
            </div>
            <div className="basis100p basis60p-sm basis50p-md">
              <Heading kind="pHeading3">Design System</Heading>
              <Paragraph>
                Time Inc. wanted a full design system to accompany its redesign
                to let each of its brands stand on their own, but still feel
                part of the Time Inc. family.
              </Paragraph>
              <Paragraph>
                I teamed up with{" "}
                <a
                  href="http://bradfrost.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles(
                    "blue-500 maison-demi text-underline hover-text-decoration-none"
                  )}
                >
                  Brad Frost
                </a>
                ,{" "}
                <a
                  href="https://bigmedium.com/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles(
                    "blue-500 maison-demi text-underline hover-text-decoration-none"
                  )}
                >
                  Josh Clark
                </a>
                ,{" "}
                <a
                  href="http://melissafrostdesign.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles(
                    "blue-500 maison-demi text-underline hover-text-decoration-none"
                  )}
                >
                  Melissa Frost
                </a>
                , and a handful of other talented folks to complete this massive
                (beautiful) undertaking.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>

      <Project3UpResponsive
        src1={<ImgTimeIncPatternLabColors />}
        src2={<ImgTimeIncPatternLabComponents />}
        src3={<ImgTimeIncPatternLabFactoids />}
        src4={<ImgTimeIncPatternLabBlocks />}
        captionTitle="Atoms. Molecules. Organisms."
        caption="Using PatternLab we built small pieces called atoms that define the colors, headings, paragraphs, etc."
        caption2="Those foundational pieces are then combined into larger components called organisms to create navigation, hero images, media blocks, etc. Eventually full-page designs were created."
      />
      <ProjectImageResponsive
        src={<ImgTimeIncBrand />}
        alt="Full page example of design system in action for Entertainment Weekly"
        captionTitle="Example of Design System in action above"
        caption="Here components large and small combine to create something that is distinctly Entertainment Weekly yet familiar under the umbrella of Time Inc."
        collaboratorMessage="Visual language by  "
        collaboratorName="Melissa Frost"
        collaboratorWebsite="http://melissafrostdesign.com/"
      />
    </div>
  )
}

export default ProjectTimeInc
