import React from "react"
import Header from "../components/portfolio/Header"
import Nav from "../components/portfolio/Nav"
import About from "../components/portfolio/About"
import ProjectConsider from "../components/portfolio/ProjectConsider"
import ProjectKickstarter from "../components/portfolio/ProjectKickstarter"
import ProjectTimeInc from "../components/portfolio/ProjectTimeInc"
import ProjectBarkBox from "../components/portfolio/ProjectBarkBox"
import ProjectForrst from "../components/portfolio/ProjectForrst"
import Footer from "../components/portfolio/Footer"

import SEO from "../components/seo"

const IndexPage = () => (
  <div>
    <SEO
      title="Portfolio | Keith Holjencin - Product Designer and Frontend Engineer. Kickstarter, BarkBox, Time Inc., Forrst. Building Consider."
      keywords={[
        `Keith Holjencin`,
        `Keith Holjencin Product Designer`,
        `Keith Holjencin Frontend Designer`,
        `BarkBox`,
        `BarkBox Designer`,
        `BarkBox Product Designer`,
        `Kickstarter Product Designer`,
        `Time Inc. Frontend Designer`,
        `Forrst Product Designer`,
        `Forrst Designer`,
        `Consider Founder`,
        `Consider App`,
        `new york city designer`,
        `nyc product designer`,
        `frontend designer`,
        `frontend design`,
        `frontend engineer`,
        `design systems`,
        `react`,
        `javascript`,
        `rails`,
        `product designer`,
        `new york`,
      ]}
    />
    <Header />
    <Nav />
    <About />
    <ProjectConsider />
    <ProjectKickstarter />
    <ProjectTimeInc />
    <ProjectBarkBox />
    <ProjectForrst />
    <Footer />
  </div>
)

export default IndexPage
