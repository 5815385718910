import React from "react"
import { Paragraph } from "./Text"
import { Heading } from "./Text"
import ProjectImageResponsive from "./ProjectImageResponsive"

import ImgConsiderHomepage from "./ImgConsiderHomepage"
import ImgConsiderAddFriend from "./ImgConsiderAddFriend"

const ProjectConsider = ({ data }) => {
  return (
    <div>
      <div className="m-auto max-w1080px px4 px10-md">
        <div className="border-top border-grey-900" id="consider">
          <div className="flex flex-wrap flex-nowrap-sm mb10 mb15-lg mt10 mt15-md mt20-lg">
            <div className="basis100p basis40p-sm basis50p-md text-center text-left-sm mb10 mb0-sm pr0 pr4-sm">
              <Heading kind="pHeading1">Consider</Heading>
              <p className="maison-book grey-900 fz16px inline-block mb5">
                2018 — Present
              </p>
              {/* <h6 className="fz16px maison-book block mb3 mb4-sm grey-900 mt2">
                Roles
              </h6> */}
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb2-sm">
                Founder
                <span className="maison-demi fz24px grey-500">
                  {" "}
                  / Side Project
                </span>
              </h3>
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb2-sm">
                Software Engineer
              </h3>
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb2-sm">
                Frontend Engineer
              </h3>
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb7">
                Product Designer
              </h3>
              <p className="maison-book fz12px fz14px-md inline-block mb2 px2 py1 grey-900 bg-yellow-500">
                Beta · Invite-only
              </p>
              <br />
              <a
                href="http://www.considerapp.com"
                target="_blank"
                rel="noopener noreferrer"
                className="maison-book fz16px mb2 text-underline grey-900 inline-block hover-text-decoration-none"
              >
                Visit project website →
              </a>
            </div>
            <div className="basis100p basis60p-sm basis50p-md">
              <Heading kind="pHeading3">What is Consider?</Heading>
              <Paragraph kind="body">
                Share individual recommendations with close friends to watch,
                listen, play, or read.
              </Paragraph>
              <Heading kind="pHeading3" className="mt8 mt10-md">
                Deepen relationships
              </Heading>
              <Paragraph>
                Send and receive meaningful recommendations that deepen your
                relationships. Your friends know you best.
              </Paragraph>
              <Heading kind="pHeading3" className="mt8 mt10-md">
                Share inside, discuss outside
              </Heading>
              <Paragraph>
                No comments. No email chains. No message boards.
              </Paragraph>
              <Paragraph>
                Share a recommendation, and then discuss{" "}
                <span className="text-nowrap inline-block">in-person</span>,
                over the phone, or wherever suits.
              </Paragraph>
              <Heading kind="pHeading3" className="mt8 mt10-md">
                Slow inbox
              </Heading>
              <Paragraph>
                Considerations arrive one at a time to help you focus, and feel
                less overwhelmed.
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <ProjectImageResponsive
        src={<ImgConsiderHomepage />}
        alt="Consider Homepage screenshot"
        captionTitle="Homepage"
        caption="Here is the homepage for Consider which is currently in invite-only beta."
      />
      <ProjectImageResponsive
        src={<ImgConsiderAddFriend />}
        captionTitle="Friend added"
        caption="The above shows what it looks like when successfully adding a friend in Consider."
      />
    </div>
  )
}

export default ProjectConsider
