import React from "react"

// Regex to replace all spaces with an empty string and change toLowerCase
// So the same projectNames for section titles and menu can be used in NavItem
export const generateAnchorLink = name =>
  name.replace(/(\s|\.)/g, "").toLowerCase()

const NavItem = ({ name, year }) => {
  const anchorLink = generateAnchorLink(name)
  return (
    <a
      href={`#${anchorLink}`}
      className="pl3 pl0-sm pt3 pb3 pt0-sm pb6-sm pb6-lg blue-500 text-decoration-none hover-text-underline block"
    >
      <h5 className="maison-demi fz21px lh30px fz18px-sm lh24px-sm fz21px-md lh30px-md mb3px">
        {name}
      </h5>
      <h6 className="maison-book fz14px lh18px fz12px-sm lh18px-sm fz13px-md lh18px-md grey-900">
        {year} <span className="blue-500">&#8594;</span>
      </h6>
    </a>
  )
}

export default NavItem
