import React from "react"
import { Paragraph } from "./Text"
import { Heading } from "./Text"

const ProjectImageResponsive = ({
  src,
  captionTitle,
  caption,
  caption2,
  collaboratorMessage,
  collaboratorName,
  collaboratorWebsite,
}) => {
  return (
    <div className="m-auto max-w1300px px0 px10-md pb5 pb10-md">
      {src}
      {captionTitle && caption && (
        <div className="m-auto max-w1080px flex flex-wrap flex-nowrap-sm justify-end px4 px10-md">
          <div className="basis100p basis40p-sm basis50p-md" />
          <div className="basis100p basis60p-sm basis50p-md">
            <Heading kind="captionTitle">{captionTitle}</Heading>
            <Paragraph>{caption}</Paragraph>
            {caption2 && <Paragraph>{caption2}</Paragraph>}
            {collaboratorMessage && (
              <Paragraph kind="collaborators">
                {collaboratorMessage}
                <a
                  href={collaboratorWebsite}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="grey-900 maison-demi text-underline hover-text-decoration-none"
                >
                  {collaboratorName}
                </a>
              </Paragraph>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProjectImageResponsive
