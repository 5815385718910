import React from "react"

const About = () => {
  return (
    <div className="m-auto max-w1080px px4 px10-md">
      <div className="py12 py20-md">
        {/* <div className="flex flex-wrap flex-nowrap-sm mb10 mb15-lg mt10">
          <div className="pb10 basis100p">
            <Heading kind="pHeading1" className="text-center text-left-sm">
              Seeking Junior Engineer roles.
            </Heading>
          </div>
        </div> */}
        {/* <div className="flex-sm flex-wrap flex-nowrap-sm mb10 mb15-lg mt10 hide">
          <div className="pb10 basis100p basis80p-sm basis70p-md basis60p-lg">
            <Heading kind="pHeading1">
              A blend of Engineering, Product Design, and Research.
            </Heading>
          </div>
        </div> */}
        <div className="flex flex-wrap flex-nowrap-sm">
          <div className="basis100p basis40p-sm basis50p-md pr0 pr4-sm">
            {/* <Heading kind="pHeading3" className="mb7 text-center text-left-sm">
              I can help your team
            </Heading> */}
            <div className="text-center text-left-sm">
              <p className=" pb3 maison-demi grey-900 fz25px fz23px-sm lh32px mb7">
                How I can add to your team
              </p>
              {/* <p className=" maison-book fz12px fz14px-md inline-block mb2 px2 py1 grey-900 bg-yellow-500 mb7">
                Seeking Junior Engineer roles
              </p> */}
            </div>
            {/* <Heading kind="pHeading2" className="text-center text-left-sm mb10">
              I have a blend of Engineering, User Research, and 10 years of
              Product Designer experience.
            </Heading> */}
            {/* <p className="maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb4">
              I have a blend of Engineering, User Research, and 10
              years of Product Designer experience.
            </p> */}
            {/* <Heading kind="pHeading3" className="mb7 text-center text-left-sm">
              I help companies and teams...
            </Heading> */}

            {/* <h3 className="maison-demi fz24px lh32px fz21px-sm lh30px-sm mb6 grey-900 inline-block">
              How I can add to your team
            </h3> */}
          </div>

          <div className="basis100p basis60p-sm basis50p-md">
            <ul>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">
                  Build and ship meaningful features
                </span>{" "}
                that map to real customer situations
              </li>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">
                  Strengthen Design and Engineering collaboration
                </span>{" "}
                through lessons learned from past team configurations, and my
                experience working in both disciplines
              </li>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">
                  Create, maintain, and evolve Design Systems
                </span>{" "}
                and tooling (e.g. React, CSS) with components that are flexible,
                reusable, and cross-browser compatible
              </li>
              {/* <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">
                  Build and launch meaningful features
                </span>{" "}
                that map to real customer problems, have agreement on what success looks like, and allow for continually learning about how our work impacts customers
                contributing to the codebase, and participating in RFCs in
                addition to Design Systems work
              </li> */}
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">
                  Conduct research to uncover what customers value
                </span>{" "}
                as well as when and what they hire your product for by using
                Jobs to be Done interviews
              </li>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb10">
                <span className="maison-demi">
                  Increase shared understanding across teams
                </span>{" "}
                of learned customer insights to inform future feature work
              </li>
              {/* <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">Build meaningful products</span>{" "}
                with features that help customers make real progress towards a
                better self
              </li> */}
              {/* <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb7">
                <span className="maison-demi">
                  Uncover what customers value
                </span>{" "}
                and find common stories around when and what they hire your
                product for by conducting research using Jobs to be Done
                interviews
              </li> */}
              {/* <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb4">
                <span className="maison-demi">Guide the product</span> by
                uncovering what customers value through Jobs to be Done user
                research
              </li> */}
            </ul>
            {/* <hr className="mb7" />
            <ul>
              <p className="maison-demi fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb5 mt2">
                I’m looking for a company that has:
              </p>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb4">
                Interest in a growing Engineer with Design sensibilities
              </li>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb4">
                Established mentoring culture
              </li>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb4">
                Support for career growth and works together to create a clear
                path forward with check-ins
              </li>
              <li className="list-disc list-outside maison-book fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 mb10">
                A strong GIF collection
              </li>
            </ul> */}
            <p className="maison-demi fz18px lh26px fz18px-md lh24px-md lh28px-lg grey-900 text-center">
              <a
                href="mailto:keith@holjencin.com"
                className="blue-500 maison-demi hover-text-underline text-decoration-none border border-blue-500 rounded block py2 px2"
              >
                Email me about joining your team
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
