import React from "react"
import { styles } from "../../utils/css"
import Logo from "./Logo"
import { Paragraph } from "./Text"
import { Heading } from "./Text"
import ProjectImageResponsive from "./ProjectImageResponsive"

import ImgProfilePage from "./ImgProfilePage"
import ImgKSROverview from "./ImgKSROverview"
import ImgKSROverview2 from "./ImgKSROverview2"

import logoKickstarter from "../../images/logo-ksr@2x.png"
import ImgGuidedSearch from "./ImgGuidedSearch"

const ProjectKickstarter = () => {
  return (
    <div>
      <div className="m-auto max-w1080px px4 px10-md">
        <div className="border-top border-grey-900 mt6" id="kickstarter">
          <Logo src={logoKickstarter} alt="Kickstarter logo" />
          <div className="flex flex-wrap flex-nowrap-sm pb10 pb20-md">
            <div className="basis100p basis40p-sm basis50p-md text-center text-left-sm mb10 mb0-sm pr0 pr4-sm">
              <Heading kind="pHeading1">Kickstarter</Heading>
              <p className="maison-book grey-900 fz16px inline-block mb5">
                2014 — 2018
              </p>
              {/* <h6 className="fz16px maison-book block mb3 mb4-sm grey-900 mt2">
                Roles
              </h6> */}
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb2-sm">
                Product Design Lead
              </h3>
              <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb7-sm">
                Frontend Designer
              </h3>
            </div>
            <div className="basis100p basis60p-sm basis50p-md">
              <Heading kind="pHeading3">Discovery Team</Heading>
              <Paragraph>
                I led Design for Kickstarter's{" "}
                <span className={styles("maison-demi")}>category pages</span>,{" "}
                <span className={styles("maison-demi")}>search</span>, and{" "}
                <span className={styles("maison-demi")}>
                  project recommendations
                </span>
                .
              </Paragraph>
              <Paragraph>
                I co-created the{" "}
                <span className={styles("maison-demi")}>research process</span>{" "}
                for conducting on-going Creator interviews using{" "}
                <span className={styles("maison-demi")}>Jobs to be Done</span>.
              </Paragraph>
              <Heading kind="pHeading3" className="mt8 mt10-md">
                Design System Team
              </Heading>
              <Paragraph>
                I contributed to creating, evolving, and maintaining the{" "}
                <span className={styles("maison-demi")}>design system</span> and{" "}
                <span className={styles("maison-demi")}>documentation</span>.
              </Paragraph>
              <Heading kind="pHeading3" className="mt8 mt10-md">
                International Team
              </Heading>
              <Paragraph>
                In a past feature team setup, I helped Kickstarter expand its
                reach to{" "}
                <span className={styles("maison-demi")}>10 new countries</span>,
                support{" "}
                <span className={styles("maison-demi")}>3 new languages</span>,
                and{" "}
                <span className={styles("maison-demi")}>12 new currencies</span>
                .
              </Paragraph>
            </div>
          </div>
        </div>
      </div>
      <ProjectImageResponsive src={<ImgKSROverview />} />
      <ProjectImageResponsive src={<ImgKSROverview2 />} />
      <ProjectImageResponsive
        src={<ImgProfilePage />}
        captionTitle="New Profile"
        caption="Profiles now act as a better place to reveal what you care about supporting."
        caption2="Improvements ranged from letting folks follow creators to new projects cards that give a better glimpse of your interests."
        collaboratorMessage="In collaboration with: "
        collaboratorName="Nicole Yeo"
        collaboratorWebsite="https://www.n-yeo.com/"
      />
      <div className="m-auto max-w1080px px4 px10-md border-top pt5 pt12-md pb6">
        <div className="flex flex-wrap flex-nowrap-sm items-center-md">
          <div className="basis100p basis40p-sm basis50p-md pr0 pr6-sm pr10-md pr12-lg">
            <ImgGuidedSearch />
          </div>
          <div className="basis100p basis60p-sm basis50p-md max-w480px-md">
            <Heading kind="captionTitle">Guided Search</Heading>
            <Paragraph />

            <Heading kind="pHeading3" className="mt6">
              Search as exploration
            </Heading>
            <Paragraph>
              Beyond just project results, traverse Kickstarter by exploring
              which categories and creators are related to your search.
            </Paragraph>

            <Heading kind="pHeading3" className="mt6">
              Available across all devices
            </Heading>
            <Paragraph>
              Guided search was developed from the start to be mobile-first.
              Whether on desktop, tablet, or mobile phone the new power of
              search is available across all devices.
            </Paragraph>

            <Heading kind="pHeading3" className="mt6">
              Did you mean...?
            </Heading>
            <Paragraph>
              Search result suggestions are provided for common spelling
              mistakes across multiple languages.
            </Paragraph>
            <Paragraph kind="collaborators">
              In collaboration with:{" "}
              <a
                href="https://github.com/orionmcc"
                target="_blank"
                rel="noopener noreferrer"
                className="grey-900 maison-demi text-underline hover-text-decoration-none"
              >
                Orion McClelland{" "}
              </a>
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectKickstarter
