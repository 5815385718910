import React from "react"
import ContactDetails from "./ContactDetails"

const Header = () => {
  return (
    <div>
      <div className="m-auto max-w1080px px4 px10-md">
        <div className="py8 pt12-sm pt25-md pb18-md">
          <h1 className="maison-demi fz39px lh48px fz48px-md lh54px-md fz60px-lg lh66px-lg font-weight-normal ls-xtight grey-900 block ls_1 mb3 mb6-sm">
            Keith Holjencin
          </h1>
          {/* <h1 className="maison-demi fz39px lh48px fz48px-md lh54px-md fz60px-lg lh66px-lg font-weight-normal ls-xtight grey-900 block ls_1 mb3 mb6-sm">
            Keith Holjencin
          </h1> */}

          {/* <h2 className="maison-book fz28px lh36px fz34px-md lh42px-md fz46px-lg lh56px-lg font-weight-normal mb1 mb4-sm grey-500 block">
            Software Engineer
            <br />
            <span className="grey-400">Former Product Designer</span>
          </h2> */}
          {/* <h2 className="maison-demi fz28px lh36px fz34px-md lh42px-md fz46px-lg lh56px-lg font-weight-normal mb2 mb4-sm grey-900 block">
            Product Designer &
            <br />
            Aspiring Software Engineer
          </h2> */}
          <h2 className="maison-demi fz32px lh42px fz34px-md lh42px-md fz54px-lg lh66px-lg font-weight-normal mb3 mb4-sm grey-900 block">
            Product Designer
            <br />& Frontend Engineer
          </h2>
          {/* <h2 className="maison-demi fz32px lh36px fz34px-md lh42px-md fz54px-lg lh66px-lg font-weight-normal mb3 mb4-sm grey-900 block">
            Product Designer
          </h2> */}
          {/* <div className="bg-yellow-500 px2 py1 maison-book inline-block">
            I am seeking Junior Engineer positions and paid internships.
          </div> */}
          {/* <h2 className="maison-demi fz28px lh36px fz34px-md lh42px-md fz46px-lg lh56px-lg font-weight-normal mb1 mb4-sm grey-900 block">
            After 10 years of being a Product Designer, I am becoming a Software
            Engineer.
          </h2> */}
          {/* <h2 className="maison-demi fz28px lh36px fz34px-md lh42px-md fz46px-lg lh56px-lg font-weight-normal mb1 mb4-sm grey-900 block">
            Software Engineer after being a{" "}
            <span className="text-nowrap">Product Designer</span> for{" "}
            <span className="text-nowrap">10 years</span>
          </h2> */}
          <ContactDetails />
        </div>
      </div>
      {/* <Nav /> */}
    </div>
  )
}

export default Header
