import React from "react"

const Logo = ({ src, alt }) => {
  return (
    <div className="text-center text-left-sm">
      <img src={src} className="mb3 mb12-sm w25 mt15 mt20-md" alt={alt} />
    </div>
  )
}

export default Logo
