import React from "react"
import { styles } from "../../utils/css"
import Logo from "./Logo"
import { Paragraph } from "./Text"
import { Heading } from "./Text"

import logoForrst from "../../images/logo-forrst@2x.png"

const ProjectForrst = () => {
  return (
    <div className="m-auto max-w1080px px4 px10-md">
      <div className="border-top border-grey-900 mt6" id="forrst">
        <Logo src={logoForrst} alt="Forrst logo" />
        <div className="flex flex-wrap flex-nowrap-sm pb10 pb20-md">
          <div className="basis100p basis40p-sm basis50p-md text-center text-left-sm mb10 mb0-sm pr0 pr4-sm">
            <Heading kind="pHeading1">Forrst</Heading>
            <p className="maison-book grey-900 fz16px inline-block mb5">
              2009 — 2012
            </p>
            {/* <h6 className="fz16px maison-book block mb3 mb4-sm grey-900 mt2">
              Role
            </h6> */}
            <h3 className="maison-demi fz28px grey-900 mt2 mt2-md mb2 mb7-sm">
              Product Designer
            </h3>
          </div>
          <div className="basis100p basis60p-sm basis50p-md">
            <Heading kind="pHeading3">Design for community building</Heading>
            <Paragraph>
              I designed to keep the quality of Forrst membership intact while
              growing to over 50,000 members with a{" "}
              <span className={styles("maison-demi")}>
                unique invite system
              </span>
              , and connecting then with career opportunities through our{" "}
              <span className={styles("maison-demi")}>Job Board</span>.
            </Paragraph>
            <Paragraph>
              In 2012, Forrst was acquired by ColourLovers, and then later by
              ZURB.
            </Paragraph>
            <Paragraph kind="collaborators">
              In collaboration with:{" "}
              <a
                href="http://kylebragger.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles(
                  "grey-900 maison-demi text-underline hover-text-decoration-none"
                )}
              >
                Kyle Bragger
              </a>
              ,{" "}
              <a
                href="https://github.com/zackkitzmiller"
                target="_blank"
                rel="noopener noreferrer"
                className={styles(
                  "grey-900 maison-demi text-underline hover-text-decoration-none"
                )}
              >
                Zack Kitzmiller
              </a>
              ,{" "}
              <a
                href="http://www.adamkopec.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles(
                  "grey-900 maison-demi text-underline hover-text-decoration-none"
                )}
              >
                Adam Kopec
              </a>
              , and{" "}
              <a
                href="http://pasquale.cool/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles(
                  "grey-900 maison-demi text-underline hover-text-decoration-none"
                )}
              >
                Pasquale D'Silva
              </a>
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectForrst
