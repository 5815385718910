import React from "react"
import { styles } from "../../utils/css"
import { Paragraph } from "./Text"
import NavItem from "./NavItem"

const projects = [
  { projectName: "Consider", year: "2019—Present" },
  { projectName: "Kickstarter", year: "2014—2018" },
  { projectName: "Time Inc.", year: "2013" },
  { projectName: "BarkBox", year: "2012" },
]

const Nav = () => {
  return (
    <div className="border-bottom max-w100p">
      <div className="max-w1080px m-auto px4 px10-md hide-sm mb12">
        <Paragraph kind="menuParagraph" className="mb4">
          Currently I'm building{" "}
          <a
            href="#consider"
            className={styles(
              "blue-500 maison-demi text-underline hover-text-decoration-none"
            )}
          >
            Consider
          </a>{" "}
          to create a calm place for close friends to privately share meaningful
          recommendations.
        </Paragraph>

        <Paragraph kind="menuParagraph">
          See my work below for : <br />{" "}
          <a
            href="#kickstarter"
            className={styles(
              "blue-500 maison-demi text-underline hover-text-decoration-none"
            )}
          >
            Kickstarter
          </a>{" "}
          <span className="grey-400 mx2px">/</span>{" "}
          <a
            href="#timeinc"
            className={styles(
              "blue-500 maison-demi text-underline hover-text-decoration-none"
            )}
          >
            Time Inc.
          </a>{" "}
          <span className="grey-400 mx2px">/</span>{" "}
          <a
            href="#barkbox"
            className={styles(
              "blue-500 maison-demi text-underline hover-text-decoration-none"
            )}
          >
            BarkBox
          </a>
        </Paragraph>
      </div>

      <div className="max-w1080px m-auto px4 px10-md hide block-sm">
        <span className="fz11px maison-demi text-uppercase inline-block mb3 mb6-sm grey-500 ls-loose">
          Portfolio
        </span>
        <div className="flex column flex-wrap row-sm flex-nowrap-sm justify-evenly">
          {projects.map((project, index) => {
            const classes = styles(
              "mb0 border-left border-none-sm basis100p basis-initial grow1-sm relative",
              index === projects.length - 1 ? "" : ""
            )
            return (
              <div className={classes} key={index}>
                <NavItem
                  key={`nav-item-${index}`}
                  name={project.projectName}
                  year={project.year}
                />
                <div className="block h9px w9px bg-white border border-blue-500 l-5px t27px absolute circle t74px-sm t80px-md hide-sm" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Nav
